<template>
  <section>
    <ApolloQuery
      :query="require('@/graphql/Patients.gql')"
      fetchPolicy="cache-and-network"
    >
      <!-- Loading -->

      <template slot-scope="{ result: { loading, error, data } }">
        <!-- Loading -->
        <div v-if="loading" class="loading apollo">Loading...</div>

        <!-- Error -->
        <div v-else-if="error" class="error apollo">An error occured</div>

        <div v-else-if="data">
          <v-card-title>
            Passengers
            <v-spacer></v-spacer>
            <v-select
              class="filter-input mr-2"
              single-line
              v-if="isAdmin"
              hide-details
              :items="
                !data.customers
                  ? [
                      {
                        text: 'All Clients',
                        value: null,
                      },
                    ]
                  : [
                      {
                        text: 'All Clients',
                        value: null,
                      },
                    ].concat(
                      data.customers.map((item) => {
                        return {
                          text: item.name,
                          value: parseInt(item.id),
                        };
                      })
                    )
              "
              v-model="customer"
              label="All Clients"
            ></v-select>
            <v-text-field
              v-model="search"
              class="filter-input"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="data.patients"
            class="elevation-1"
            :footer-props="{ 'items-per-page-options': [15, 30, 50, 100, -1] }"
            :items-per-page="30"
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <!-- <v-toolbar-title>My CRUD</v-toolbar-title> -->
                <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="500px" persistent>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on"
                      >Add Passenger</v-btn
                    >
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>

                    <AddEditPatientComponent
                      ref="addEditPatientComponent"
                      :editedItem="editedItem"
                      :data="data"
                    />

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="close"
                        >Cancel</v-btn
                      >
                      <v-btn color="blue darken-1" text @click="save(data)"
                        >Save</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.firstName="{ item }">
              {{ item.firstName }}
            </template>
            <template v-slot:item.lastName="{ item }">
              {{ item.lastName }}
            </template>
            <template v-slot:item.phone="{ item }">{{ item.phone }}</template>
            <template v-slot:item.address="{ item }">
              {{ item.address }}
            </template>
            <template v-slot:item.note="{ item }">{{ item.note }}</template>
            <template v-slot:item.locations="{ item }">
              {{
                !item.locations
                  ? "-"
                  : item.locations.map((item) => item.name).join(", ")
              }}
            </template>
            <template v-slot:item.contacts="{ item }">
              {{
                !item.contacts
                  ? "-"
                  : item.contacts.map((item) => item.name).join(", ")
              }}
            </template>
            <template v-slot:item.customer="{ item }">
              {{ item.customer ? item.customer.name : "-" }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item, data)"
                    v-bind="attrs"
                    v-on="on"
                    >mdi-pencil</v-icon
                  >
                </template>
                <span>Edit Passenger</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    @click="deleteItem(item, data)"
                    v-bind="attrs"
                    v-on="on"
                    v-if="isAdmin || isClientAdmin"
                    >mdi-delete</v-icon
                  >
                </template>
                <span>Delete Passenger</span>
              </v-tooltip>
            </template>
            <template v-slot:no-data>
              <v-btn color="primary" @click="initialize">Reset</v-btn>
            </template>
          </v-data-table>
        </div>

        <!-- No result -->
        <div v-else class="no-result apollo">No results</div>
      </template>
    </ApolloQuery>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import AddEditPatientComponent from "@/components/data/AddEditPatientComponent";

import { DateTime } from "luxon";

export default {
  components: {
    AddEditPatientComponent,
  },
  data: () => ({
    dialog: false,
    //
    search: "",
    customer: null,
    // headers:
    editedIndex: -1,
    editedItem: {},
    defaultItem: {
      locations: [],
      contacts: [],
    },
    //
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Passenger" : "Edit Passenger";
    },
    headers() {
      return [
        {
          text: "First Name",
          align: "start",
          sortable: false,
          value: "firstName",
          filterable: false,
        },
        {
          text: "Last Name",
          sortable: false,
          value: "lastName",
          filterable: false,
        },
        { text: "Phone Number", value: "phone", filterable: false },
        { text: "Note", value: "note", filterable: false },
        { text: "Addresses", value: "locations", filterable: false },
        { text: "Contacts", value: "contacts", filterable: false },
        {
          text: "Client",
          value: "customer",
          roles: ["Admin"],
          filterable: false,
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          filter: this.searchFilter,
        },
      ].filter(
        (item) => !item.roles || item.roles.includes(this.user.role.name)
      );
    },
    ...mapGetters(["user", "strapi", "isAdmin", "isClientAdmin"]),
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  mounted() {
    this.initialize();
  },

  methods: {
    searchFilter(_value, _search, item) {
      if (this.customer) {
        if (!item.customer || parseInt(item.customer.id) != this.customer)
          return false;
      }

      var searchWord = this.search.toLowerCase();
      if (
        (item.firstName && item.firstName.toLowerCase().includes(searchWord)) ||
        (item.lastName && item.lastName.toLowerCase().includes(searchWord)) ||
        (item.phone && item.phone.toLowerCase().includes(searchWord))
      )
        return true;
      if (item.note && item.note.toLowerCase().includes(searchWord))
        return true;
      return false;
    },
    initialize() {
      if (
        this.user.role.name == "ClientUser" ||
        this.user.role.name == "ClientAdmin"
      ) {
        this.defaultItem.customer = this.user.customer
          ? parseInt(this.user.customer.id)
          : undefined;
      }
      this.editedItem = this.lodash.cloneDeep(this.defaultItem);
    },

    editItem(item, data) {
      this.editedIndex = data.patients.findIndex((i) => i.id == item.id);
      // Object.assign(this.editedItem, item);
      this.editedItem = this.lodash.cloneDeep(item);
      this.editedItem.customer = item.customer
        ? parseInt(item.customer.id)
        : undefined;

      this.dialog = true;
      // this.$nextTick(() => {
      //   this.$refs.googleSearch.textValue = item.address;
      // });
    },

    async deleteItem(item, data) {
      const index = data.patients.findIndex((i) => i.id == item.id);
      var result = confirm("Are you sure you want to delete this item?");
      if (result) {
        await this.strapi.deleteEntry("patients", item.id);
        data.patients.splice(index, 1);
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        // Object.assign(this.editedItem, this.defaultItem);
        this.editedItem = this.lodash.cloneDeep(this.defaultItem);
        this.editedIndex = -1;
      });
    },

    tConvert(time) {
      if (!time) return "Time not selected";
      // Check correct time format and split into components
      time = time
        .toString()
        .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (time.length > 1) {
        // If time format correct
        time = time.slice(1); // Remove full string match value
        time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
      }
      return time.join(""); // return adjusted time or original string
    },

    async save(data) {
      this.$refs.addEditPatientComponent.$refs.form.validate();
      if (this.$refs.addEditPatientComponent.$refs.form.validate(true)) {
        var time = DateTime.local()
          .toJSON()
          .slice(11, 16);
        this.editedItem.timeNotification = this.tConvert(time);

        // if (this.$refs.addEditPatientComponent)
        // console.log('this.editedIndex', this.editedIndex);
        if (this.editedIndex > -1) {
          var updatedItem = await this.strapi.updateEntry(
            "patients",
            parseInt(this.editedItem.id),
            this.editedItem
          );
          Object.assign(data.patients[this.editedIndex], updatedItem);
        } else {
          //     console.log(this.user.role.name);
          // console.log(this.user.role.name == "ClientUser" ||
          // this.user.role.name == "ClientAdmin");
          if (
            this.user.role.name == "ClientUser" ||
            this.user.role.name == "ClientAdmin"
          ) {
            this.editedItem.customer = this.user.customer
              ? parseInt(this.user.customer.id)
              : undefined;
          }
          var newItem = await this.strapi.createEntry(
            "patients",
            this.editedItem
          );
          // console.log(newItem);
          data.patients.push(newItem);
        }
        this.close();
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
