<template>
  <v-card-text>
    <v-container>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              v-model="editedItem.firstName"
              :rules="requiredRules"
              hide-details="auto"
              label="First Name"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              v-model="editedItem.lastName"
              :rules="requiredRules"
              hide-details="auto"
              label="Last Name"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <div
              v-if="duplicateNameFound"
              style="color: red; margin-left: 20px"
            >
              Duplicate Name Found
            </div>
          </v-col>
        </v-row>
        <v-col cols="12" sm="12" md="12" class="custom-error-handler-top-item">
          Primary Contact Number
          <div style="display: flex; flex-flow: row">
            <VuePhoneNumberInput
              placeholder="Primary Contact Number"
              v-model="editedItem.phone"
              required
              no-country-selector
              :only-countries="['US']"
              class="mb-6 mr-4"
              @phone-number-blur="changeNumFormat('editedItem')"
            />
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="#032465"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  @click.native="showAddContact = !showAddContact"
                >
                  <v-icon>{{
                    !showAddContact ? "mdi-plus" : "mdi-close"
                  }}</v-icon>
                </v-btn>
              </template>
              <span>Add Contact</span>
            </v-tooltip>
          </div>
          <div v-if="duplicatePhoneFound" style="color: red; margin-left: 10px">
            Duplicate Phone Found
          </div>
          <v-text-field
            v-model="editedItem.phone"
            hide-details="auto"
            :rules="requiredPhoneRules"
            class="custom-error-handler-input mt-3"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="12">
          <div
            class="d-flex flex-row justify-space-between"
            v-if="editedItem.contacts && editedItem.contacts.length > 0"
          >
            Other Contacts
          </div>
          <div
            v-for="(loc, locIdx) in editedItem.contacts"
            :key="locIdx"
            class="d-flex flex-row justify-space-between mt-4"
          >
            <div>{{ loc.name }}, {{ loc.phone }}</div>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="#032465"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  @click="editedItem.contacts.splice(locIdx, 1)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Delete Contact</span>
            </v-tooltip>
          </div>

          <div
            class="mt-4 d-flex flex-column justify-center"
            v-if="showAddContact == true"
          >
            <v-text-field
              v-model="editedContact.name"
              label="Phone Type (Mobile, Home...)"
              outlined
            ></v-text-field>
            <!-- <v-text-field
                              v-model="editedContact.phone"
                              label="Phone Number"
                              outlined
            ></v-text-field>-->
            <div class="d-flex flex-column custom-error-handler-top-item mb-6">
              Phone
              <VuePhoneNumberInput
                v-model="editedContact.phone"
                required
                no-country-selector
                :only-countries="['US']"
                class="mt-1"
              @phone-number-blur="changeNumFormat('editedContact')"
              />
              <v-text-field
                v-model="editedContact.phone"
                hide-details="auto"
                :rules="requiredPhoneRules"
                class="custom-error-handler-input"
                disabled
              ></v-text-field>
            </div>
            <v-btn
              class="mt-4"
              color="#032465"
              dark
              :disabled="
                !editedContact.phone ||
                !editedContact.name ||
                editedContact.phone.trim().length == 0 ||
                editedContact.name.trim().length == 0 ||
                editedContact.phone.trim().length != 14
              "
              @click="
                editedItem.contacts
                  ? editedItem.contacts.push(editedContact)
                  : (editedItem.contacts = [editedContact]);
                editedContact = {};
                showAddContact = false;
              "
              >Add</v-btn
            >
          </div>
        </v-col>

        <v-col cols="12" sm="12" md="12">
          <div class="d-flex flex-row justify-space-between">
            <div class="custom-error-handler-top-item" style="width: 100%">
              Addresses

              <v-text-field
                v-model="editedItem.locations"
                hide-details="auto"
                :rules="requiredArrayRules"
                class="custom-error-handler-input"
                style="top: -30px; left: 14px"
                disabled
              ></v-text-field>
            </div>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="#032465"
                  dark
                  @click.native="showAddLocation = !showAddLocation"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>{{
                    !showAddLocation ? "mdi-plus" : "mdi-close"
                  }}</v-icon>
                </v-btn>
              </template>
              <span>Add Address</span>
            </v-tooltip>
          </div>
          <div
            v-for="(loc, locIdx) in editedItem.locations"
            :key="locIdx"
            class="d-flex flex-row justify-space-between mt-4"
          >
            <div>{{ loc.name }} ({{ loc.address }})</div>
            <v-btn
              color="#032465"
              dark
              @click="editedItem.locations.splice(locIdx, 1)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
          <div
            class="mt-4 d-flex flex-column justify-center"
            v-if="showAddLocation == true"
          >
            <v-text-field
              v-model="editedAddress.name"
              label="(Home, Facility....)"
              outlined
            ></v-text-field>
            <VueGooglePlaces
              :api-key="apiKey"
              ref="googleSearch"
              country="usa"
              class="google-place-search"
              placeholder="Address"
              v-model="editedAddress.address"
              @placechanged="onPlaceChanged"
            />
            <v-text-field
              v-model="editedAddress.note"
              label="Suite, Appt or Note"
              outlined
              class="mt-3"
            ></v-text-field>
            <!-- {{editedItem}} -->
            <v-btn
              color="#032465"
              dark
              :disabled="
                !editedAddress.address ||
                !editedAddress.name ||
                editedAddress.address.trim().length == 0 ||
                editedAddress.name.trim().length == 0
              "
              @click="
                editedItem.locations
                  ? editedItem.locations.push(editedAddress)
                  : (editedItem.locations = [editedAddress]);
                editedAddress = {};
                showAddLocation = false;
              "
              >Add</v-btn
            >
          </div>
        </v-col>

        <v-col cols="12" sm="12" md="12">
          <v-text-field
            v-model="editedItem.note"
            label="Note"
            outlined
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="12" md="12" v-if="isAdmin">
          <v-select
            :rules="requiredRules"
            :items="
              !data.customers
                ? [
                    {
                      text: 'None',
                      value: null,
                    },
                  ]
                : [
                    {
                      text: 'None',
                      value: null,
                    },
                  ].concat(
                    data.customers.map((item) => {
                      return {
                        text: item.name,
                        value: parseInt(item.id),
                      };
                    })
                  )
            "
            v-model="editedItem.customer"
            label="Client"
            outlined
          ></v-select>
        </v-col>
      </v-form>
    </v-container>
  </v-card-text>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    editedItem: {
      type: Object,
    },
    data: {
      type: Object,
    },
  },
  methods: {
    onPlaceChanged(place) {
      this.editedAddress.latitude = place.latitude;
      this.editedAddress.longitude = place.longitude;
    },
    formatPhoneNumber(str) {
      //Filter only numbers from the input
      let cleaned = ("" + str).replace(/\D/g, "");

      //Check if the input is of correct length
      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

      if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
      }

      return null;
    },
    // updateValue(val) {
    //   var valClean = this[val].phone
    //     ? this[val].phone.replace(/\D/g, "")
    //     : this[val].phone;
    //   if (valClean && valClean.length > 10) {
    //     this[val].phone = this.formatPhoneNumber(valClean.substring(0, 10));
    //     this.$forceUpdate();
    //   }
    // },
    changeNumFormat(val) {
      const valClean = this[val].phone
        ? this[val].phone.replace(/\D/g, "")
        : this[val].phone;
      if (valClean && valClean.length && valClean.length === 10) {
        this[val].phone = this.formatPhoneNumber(valClean.substring(0, 10))
      }
    },
    checkDuplicateName() {
      // for (let i = 0; i < this.patients.length; i++) {
      //   if (
      //     this.patients[i].firstName &&
      //     this.patients[i].lastName &&
      //     this.editedItem.firstName &&
      //     this.editedItem.lastName &&
      //     this.editedItem.lastName.trim().toLowerCase() ==
      //       this.patients[i].lastName.trim().toLowerCase() &&
      //     this.editedItem.firstName.trim().toLowerCase() ==
      //       this.patients[i].firstName.trim().toLowerCase()
      //   ) {
      //     this.duplicateNameFound = true;
      //     return;
      //   }
      // }
      for (const patient of this.patients.filter(
        (el) => parseInt(el.id) !== parseInt(this.editedItem.id)
      )) {
        if (
          patient &&
          patient.firstName &&
          patient.lastName &&
          this.editedItem &&
          this.editedItem.firstName &&
          this.editedItem.lastName &&
          this.editedItem.firstName.trim().toLowerCase() === patient.firstName.trim().toLowerCase() &&
          this.editedItem.lastName.trim().toLowerCase() === patient.lastName.trim().toLowerCase()
        ) {
          this.duplicateNameFound = true
          return
        }
      }
      this.duplicateNameFound = false;
    },
    checkDuplicatePhone() {
      var formatedPhoneNumber = this.formatPhoneNumber(this.editedItem.phone);
      // for (let i = 0; i < this.patients.length; i++) {
      //   if (this.patients[i].phone == formatedPhoneNumber) {
      //     this.duplicatePhoneFound = true;
      //     return;
      //   }
      // }
      for (const patient of this.patients.filter(
        (el) => parseInt(el.id) !== parseInt(this.editedItem.id)
      )) {
        if (patient.phone === formatedPhoneNumber) {
          this.duplicatePhoneFound = true;
          return;
        }
      }
      this.duplicatePhoneFound = false;
    },
  },
  apollo: {
    Patients: {
      prefetch: true,
      query: require("@/graphql/Patients.gql"),
      fetchPolicy: "cache-and-network",
      update: (data) => data,
      result(data) {
        if (!data.data) return;
        // console.log("patients", data);
        this.patients = data.data.patients;
      },
    },
  },
  computed: {
    ...mapGetters([
      "user",
      "isAdmin",
      "isClientAdmin",
      "isClientUser",
      "isDriver",
    ]),
  },
  watch: {
    "editedItem.firstName"() {
      this.checkDuplicateName();
    },
    "editedItem.lastName"() {
      this.checkDuplicateName();
    },
    "editedItem.phone"() {
      this.checkDuplicatePhone();
    },
  },
  mounted() {
    if (!this.editedItem) {
      this.editedItem = {};
    }
    if (
      this.user.role.name == "ClientUser" ||
      this.user.role.name == "ClientAdmin"
    ) {
      this.editedItem.customer = this.user.customer
        ? parseInt(this.user.customer.id)
        : undefined;
    }
  },
  data: () => ({
    editedAddress: {},
    showAddLocation: false,
    editedContact: {},
    showAddContact: false,
    //
    apiKey: "AIzaSyBFwsSWdZdUYzlomq7JyucZdEx8hTEjRnU",
    //  :rules="requiredRules"
    requiredRules: [(v) => !!v || "Field is required"],
    requiredPhoneRules: [
      (v) => !!v || "Field is required",
      (v) => (v && v.replace(/\D/g, "").length === 10) || "Incorrect phone number",
    ],
    requiredArrayRules: [
      (v) => !!v || "Field is required",
      (v) => v.length > 0 || "At least one address is required",
    ],
    valid: false,
    //
    patients: [],
    duplicatePhoneFound: false,
    duplicateNameFound: false,
  }),
};
</script>
